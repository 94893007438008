


/* header options Flat darkblue */
div.header {
    /*margin: -6px;
    padding: 7px;*/
    background-color: var(--white);
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    vertical-align: baseline;
    list-style: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    text-rendering: optimizeLegibility;
    border-collapse: separate;
    position: relative;
    min-height: 60px;
   /* border-bottom: grey solid 1px;*/
}

    div.header h1.title {
        color: var(--blue-v1);
        font-weight: bold;
        margin:0;
        padding-top:18px;
        padding-left:15px;
        font-size:1.3rem;
    }

    #dashboard-header {
        margin-left: 18px; 
        margin-top: 18px;
        margin-bottom: 18px;
    }

    #dashboard-header h1 {
        display: inline;
        color: var(--black);
        font-weight: bold;
        vertical-align: middle;
        font-size:2rem;
    }
    
    #dashboard-header img {
        height: 2.5rem;
        vertical-align: middle;
    }



    div.header h1.title {
        color: var(--blue-v1);
        font-weight: bold;
        margin:0;
        padding-top:18px;
        padding-left:15px;
        font-size:1.3rem;
    }

    div.header #help {
        position: absolute;
        right: -10px;
        top: -27px;
        z-index: 3;
        display: block;
    }

        div.header #help .toggle {
            width: 129px;
            height: 32px;
            position: absolute;
            top: 16px;
            right: 10px;
            z-index: 4;
        }

            div.header #help .toggle a {
                background: url("assets/v1/images/img_help_&_tools.jpg") no-repeat left top;
            }

        div.header #help .options {
            clear: right;
            background: #fefefe;
            background: url("assets/v1/images/img_help_&_tools_background.jpg") repeat-x left bottom white;
            position: relative;
            top: 0px;
            height: 115px;
            z-index: 3;
            padding: 37px 10px 5px;
            border: 2px solid #279ce2;
            border-top: 0px;
            border-right: 0px;
        }

            div.header #help .options .column {
                float: left;
                display: block;
                font-family: 'Open Sans';
                margin-right: 20px;
            }

                div.header #help .options .column h4 {
                    font-weight: bold;
                    margin-bottom: 4px;
                }

                div.header #help .options .column ul {
                    padding-left: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                }

                    div.header #help .options .column ul li {
                        padding: 2px 4px;
                        list-style: none;
                    }

                        div.header #help .options .column ul li a {
                            text-decoration: none;
                        }

.b2g_support_menu {
    float: right;
    position: absolute;
    top: 8px;
    right: 10px;
}

    .b2g_support_menu .b2g_support_menu_wrapper {
        position: relative;
        margin-top:.8rem;
    }

        .b2g_support_menu .b2g_support_menu_wrapper ul {
            display: flex;
            justify-content: space-between;
            list-style: none;
            text-align: center;
            vertical-align: middle;
        }

            .b2g_support_menu .b2g_support_menu_wrapper ul li {
                position: relative;
                margin: 0 5px;
            }

                .b2g_support_menu .b2g_support_menu_wrapper ul li .fa {
                    font-size: 18px;
                    color: var(--blue-v1);
                    cursor: pointer;
                    height: 25px;
                    width: 25px;
                    line-height: 25px;
                }

                    .b2g_support_menu .b2g_support_menu_wrapper ul li .fa:hover {
                        border-radius: 50%;
                        background: #eee;
                    }

                    .b2g_support_menu .b2g_support_menu_wrapper ul li .fa:focus {
                        border-radius: 50%;
                        background: red;
                    }

                .b2g_support_menu .b2g_support_menu_wrapper ul li ul {
                    display: none;
                }

                    .b2g_support_menu .b2g_support_menu_wrapper ul li ul li {
                        margin: 10px;
                    }

                .b2g_support_menu .b2g_support_menu_wrapper ul li:hover ul {
                    display: block;
                    position: absolute;
                    top: 22px;
                    right: 7px;
                    z-index: 10;
                    background: #fff;
                    border-radius: 3px;
                    padding: 5px;
                    width: 150px;
                    border: 1px solid #eee;
                    text-align: left;
                    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                }

                    .b2g_support_menu .b2g_support_menu_wrapper ul li:hover ul li {
                        margin: 10px;
                    }

                        .b2g_support_menu .b2g_support_menu_wrapper ul li:hover ul li a {
                            text-decoration: none;
                        }
                        .b2g_support_menu .b2g_support_menu_wrapper .b2g_support_menu_title h4{
                            font-size:1rem;
                        }
                        .b2g_support_menu .b2g_support_menu_wrapper ul li span,
                        .b2g_support_menu .b2g_support_menu_wrapper ul li a{
                            font-size:.8rem;
                        }

#navigation {
    border-bottom: none;
    background-color: var(--greyverylight);
    margin-top: 0;
    border-top: 1px solid var(--gregyverylight);
    margin-top: -8px;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom:5px;
}

    #navigation ul {
        list-style-type: none;
        padding-left: 10px;
        margin-bottom: 0;
        border-bottom: 1px solid #eee;
        margin-top: 0;
    }

    #navigation > ul > li {
        display: inline-block;
        padding: 8px;
        margin: 0px 1px;
        position: relative;
        cursor: pointer;
        border-bottom: 1.5px solid #eee;
    }

        #navigation > ul > li a {
            text-decoration: none;
            position: relative;
            color: var(--greydark);
            /*font-family: Arial;*/
        }

        #navigation > ul > li:hover {
            /*background: #eee;*/
            background: var(--grey);
            border-radius: 2px;
        }


        #navigation > ul > li a:hover {
            color: var(--black);
        }

        #navigation > ul > li.active {
            /*background: linear-gradient(180deg, #d4d0c8, #efeeee);*/
            border-bottom: 1.5px solid var(--blue);
        }

            #navigation > ul > li.active a {
                border-radius: 3px;
            }


        #navigation > ul > li.parent {
        }

            #navigation > ul > li.parent .fa.fa-sort-down {
                color: var(--greydark);
                vertical-align: top;
                margin-left: 3px;
            }

            #navigation > ul > li.parent > ul {
                display: none;
                position: absolute;
                background-color: var(--white);
                left: 0;
                top: 28px;
                padding: 5px;
                -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                z-index: 9;
            }

                #navigation > ul > li.parent > ul > li {
                    background-color: var(--white);
                    min-width: 55px;
                    padding: 8px 5px;
                    border-radius: 2px;
                    white-space: nowrap;
                    margin-bottom: 2px;
                }

                    #navigation > ul > li.parent > ul > li:hover {
                        background: var(--grey);
                    }

                    #navigation > ul > li.parent > ul > li.active {
                        background-color: var(--blue);
                    }

                        #navigation > ul > li.parent > ul > li.active a {
                            color: white;
                        }

                    #navigation > ul > li.parent > ul > li a {
                        color: var(--greydark);
                    }

            #navigation > ul > li.parent:hover > ul {
                display: initial;
            }



    #navigation .detail {
        /*border-top: 1px solid var(--greydark);*/
        padding: 6px 4px;
        border-bottom: 1px solid #eee;
    }


        #navigation .detail:after {
            content: "";
            display: table;
            clear: both;
        }

/* Fixed Nav */
body.nav_fixed #layout_container {
    margin-top: 120px;
}
body.nav_fixed #navigation {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin-top: 0;
    z-index: 5;
    /*border-bottom: 1px solid var(--greydark);*/
}
    body.nav_fixed #navigation .detail {
    }




@media (max-width: 576px) {
    div.header .title {
    }

    .b2g_support_menu {
        top: 3px;
    }

    .b2g_support_menu .b2g_support_menu_wrapper {
        margin-top:1.3rem;
    }
}


@media (min-width: 576px) {
    #navigation {
    }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {

    #navigation {
    }
}

@media (min-width: 1200px) {
}
