/* Bootstrap Overrides */
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    font-weight: bold;
}

.fw-medium {
    font-weight:500
}

.fw-bold {
    font-weight:700
}

strong{
    font-weight: bold;
}

.row {
    --bs-gutter-x: 0.75rem !important;
}

.flex {
    display: flex!important;
}