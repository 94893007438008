/* Prime React Overrides */
.p-component {
    font-family:'Open Sans';
}

.p-component.p-focus{
    outline: 3px solid var(--sky) ;
    border-color:var(--sky) ;
}

/* Checkbox styles */
.p-checkbox {
    vertical-align: middle;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--blue-80);
    background: var(--blue-70);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--blue-80);
    background: var(--blue-70);
    color: var(--white);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 3px solid var(--sky) !important;
    outline-offset: 0;
    border-color: var(--sky) !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: var(--blue-70);
    border-color: var(--blue-80);
}

.p-checkbox .p-checkbox-input {
    border: 0px;
    border-radius: 4px;
    border-color: var(--blue-80);
}

.p-checkbox.p-highlight .p-checkbox-box {
    background: var(--blue-v1);
    border-color: var(--blue);
}

/* Toggle styles */
.p-inputswitch {
    vertical-align: middle;    
}

.p-inputswitch .p-inputswitch-box.p-highlight {
    border-color: var(--blue-80);
    background: var(--blue-70);
}

.p-inputswitch:not(.p-inputswitch-disabled) .p-inputswitch-box.p-highlight:hover {
    border-color: var(--blue-80);
    background: var(--blue-70);
    color: var(--white);
}

.p-inputswitch .p-inputswitch-slider {
    border-radius: 30px;
}

.p-inputswitch .p-inputswitch-slider:before {
    border-radius: 30px;
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
    background: var(--blue-v1);
}

.p-inputswitch .p-component .p-highlight {
    outline: 3px solid var(--sky) !important;
    border-color: var(--sky) !important;
}


/* Dropdown styles */
.p-dropdown{
    color: var(--blue-v1) !important;
    font-family: 'Open Sans' !important;
    font-weight: bold !important;
}
.p-dropdown .p-dropdown-label.p-placeholder{
    color: var(--blue-v1) !important;
}

.p-multiselect-label{
    color: var(--blue-v1) !important;
    font-family: 'Open Sans' !important;
    font-weight: bold !important;
}

.p-column-filter .p-multiselect .p-multiselect-label {
    padding: 0 0.5rem;
}

.p-multiselect.p-focus, .p-dropdown.p-focus {
    outline: 3px solid var(--sky) !important;
    border-color: var(--sky) !important;
}

/* Input styles */
.p-autocomplete-multiple {
    width: 100% !important;
}

.p-autocomplete-multiple .p-autocomplete-multiple-container {
    width: 100% !important;
}

.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
    background-color: var(--steel-20);
    color: var(--black-80);
    border-radius: 16px;
    margin: 0.25rem 0 0.25rem 0;
    display: inline-block;
    width: 100% !important;
}

input:focus, button:focus {
    border-radius: 3px;
    outline: 3px solid var(--sky) !important;
    outline-offset: 0;
    border-color: var(--sky) !important;
}

input::placeholder{
    color:grey;
    font-weight: 500;
    font-family: 'Open Sans';
}

/* Button styles */
.p-button{
    color:var(--black) !important;
    background: var(--gray-10) !important;
    border: 1px solid var(--greyalternate) !important;
    box-shadow:none !important;
    border-radius: 3px;
}
.p-button:hover{
    border: 1px solid var(--greydark) !important;
}

.p-button-loading .p-button-label {
    margin-left: 0.25rem;
}

.p-button.p-button-info{
    color:var(--black) !important;
    background: var(--gray-10) !important;
    border: 1px solid var(--greyalternate) !important;
}
.p-button.p-button-info:hover{
    border: 1px solid var(--greydark) !important;
}

.p-button.p-button-blue{
    color:var(--white) !important;
    background: var(--steel-70) !important;
    border: 1px solid var(--steel-70) !important;
}
.p-button.p-button-blue:hover{
    border: 1px solid black !important;
}

.p-button.p-button-success{
    color:var(--white) !important;
    background: var(--green90) !important;
    border: 1px solid var(--green90) !important;
}
.p-button.p-button-success:hover{
    border: 1px solid var(--green) !important;
}

.p-button.p-button-warning{
    color:var(--black) !important;
    background: var(--yellow) !important;
    border: 1px solid var(--yellow) !important;
}
.p-button.p-button-warning:hover{
    border: 1px solid var(--black) !important;
}

.p-button.p-button-danger{
    color:var(--white) !important;
    background: var(--red) !important;
    border: 1px solid var(--red) !important;
}
.p-button.p-button-danger:hover{
    border: 1px solid var(--black) !important;
}

.p-button.p-button-dark-blue{
    color:var(--white) !important;
    background: var(--blue-60) !important;
    border: 1px solid var(--blue-80) !important;
}
.p-button.p-button-dark-blue:hover{
    border: 1px solid var(--blue-90) !important;
    background: var(--blue-70) !important;
}
.p-button.p-button-dark-blue:active{
    border: 1px solid var(--blue-90) !important;
    background: var(--blue-70) !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.p-button.p-return-button{
    color:var(--blue-90) !important;
    background: var(--blue-10) !important;
    border: 1px solid var(--greyalternate) !important;
}

.p-button.p-button-outlined{
    font-weight: bold;
    font-size:.9rem;
    background-color: var(--white) !important;
    border: 1px solid var(--greydark) !important;
    color:var(--greydark);
}

.p-button.p-button-outlined.p-button-info{
}
.p-button.p-button-outlined.p-button-info:hover{
    background-color: var(--grey50) !important;
}

.p-button.p-button-outlined.p-button-success{
    border: 1px solid var(--green90) !important;
    color:var(--green) !important;
}
.p-button.p-button-outlined.p-button-success:hover{
    background-color: var(--green10) !important;
}

.p-button.p-button-outlined.p-button-warning{
    border: 1px solid var(--yellow) !important;
    color:var(--yellow) !important;
}
.p-button.p-button-outlined.p-button-warning:hover{
    background-color: var(--yellow10) !important;
}

.p-button.p-button-outlined.p-button-danger{
    border: 1px solid var(--red) !important;
    color:var(--red) !important;
}
.p-button.p-button-outlined.p-button-danger:hover{
    background-color: var(--red10) !important;
}

.p-button.p-button-sm, .p-button.p-button-small{
    font-size: 1rem;
    padding: 0rem .25rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.p-button.p-button-lg, .p-button.p-button-large{
    padding: 0.7rem .9rem !important;
    font-size: 1.2rem;
}

.p-button-v2 {
    text-align: center;
    color: var(--black) !important;
    background: var(--gray-10) !important;
    border: 1px solid var(--greyalternate) !important;
    box-shadow: none !important;
    border-radius: 3px;
}

p-button-v2:hover {
    border: 1px solid var(--greydark) !important;
}

.p-button-v2.p-button-v2-info {
    color: var(--black) !important;
    background: var(--grey) !important;
    border: 1px solid var(--greyalternate) !important;
}

    .p-button-v2.p-button-v2-info:hover {
        border: 1px solid var(--greydark) !important;
    }

    .p-button-v2.p-button-v2-outlined {
        font-weight: bold;
        font-size: .9rem;
        background-color: transparent !important;
        border: 1px solid var(--greydark) !important;
        color: var(--greydark);
    }

.p-button {
    background: #007bff;
    border: 1px solid #007bff;
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    padding: .5rem .75rem;
    transition: background-color .15s,border-color .15s,box-shadow .15s;
}

/* Dialog */
.p-dialog{
    min-width:300px;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 0px;
}
/* */

/* Tables */
.p-datatable .p-datatable-thead > tr > th[role=columnheader] {
    border-bottom: 1px solid var(--greydark);
    background: var(--steel-10);
    font-weight: bold;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.4rem 0.3rem;
    font-size: .9rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.3rem 0.5rem;
    font-size: .9rem;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: var(--light-blue);
    color: black;
}

.p-datatable .p-datatable-tbody > tr.p-highlight .p-chip {
    background: var(--white);
}

.p-datatable .p-datatable-tbody > .p-selectable-row:focus{
    outline: 2px solid var(--sky) !important;
}

.p-filter-column .p-inputtext{
    padding:0 0.5rem !important;
}

.p-inputtext.p-focus{
    border-color: 2px solid var(--sky) !important;
    box-shadow: none !important;
}

.p-datatable-row-expansion {
    background: var(--gray-5) !important;
}

.p-datatable .p-datatable-tbody>tr>td {
    border: solid #dee2e6;
    border-width: 1px 0 0;
    padding: 1rem;
    text-align: left;
}

/* Alerts */
.p-toast-message-icon{
    font-size: 1rem !important;
    margin-top: 7px !important;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.2rem 0 0 0;
}


.p-toast .p-toast-message.p-toast-message-success{
    background-color:var(--green);
    color: var(--white) !important;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close-icon,.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon{
    color: var(--white) !important;
}

.p-toast .p-toast-message.p-toast-message-info{
    background-color:var(--blue);
    color: var(--white) !important;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close-icon,.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon{
    color: var(--white) !important;
}

.p-toast .p-toast-message.p-toast-message-warn{
    background-color:var(--yellow);
    color:var(--black) !important;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close-icon,.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon{
    color: var(--black) !important;
}

.p-toast .p-toast-message.p-toast-message-error{
    background-color:var(--red);
    color:var(--white) !important;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close-icon,.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon{
    color: var(--white) !important;
}

.p-toast .p-toast-message.p-toast-message-error a:hover{
    color: var(--white) !important;
}

/* Paginator styling */
.p-paginator {
    color: black !important;
}

.p-paginator-bottom {
    padding-bottom: 0px !important;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    color: black;
    border: none;
    border-radius: 0;
    margin: 0.143rem;
    border-radius: 50%;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    color: black;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    border: none;
    color: black;
    margin: 0.143rem;
    transition: box-shadow 0.15s;
    border-radius: 50%;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--blue-v1);
    border-color: var(--blue-v1);
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    color: black;
}

.p-paginator .p-dropdown {
    margin-right: 0.5rem;
}

.p-datatable .p-sortable-column.p-highlight {
    background-color: var(--gray-10);
    color: var(--blue-v1);
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--blue);
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    color: red;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: red;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    color: red;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: red;
}

.p-filter-column .p-checkbox {
    margin-left: 0.2rem !important;
}

.p-datatable .p-datatable-header {
    font-weight: 400;
}

.p-datatable .p-sortable-column .p-column-title {
    white-space: nowrap;
}

.p-datatable .p-sortable-column:focus{
    border: 2px solid var(--sky) !important;
    box-shadow: none !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: #fff;
    font-style: bold;
}

/* Tab menu and toolbar styling */
.p-toolbar{
    padding: 0.3rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-left: -1rem !important;
    margin-right: -1rem !important;
    background-color:var(--greyverylight) !important;
}

.b2g-v2.p-tabmenu .p-tabmenu-nav {
    border: none;
    margin-bottom: 0.5rem;
    gap: 0.25rem !important;
}

.b2g-v2 .p-tabmenu-nav {
    flex-wrap: wrap;
}

.b2g-v2.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0.5rem;
    border: solid var(--steel-20);
    border-width: 1px;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    color: red;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight-red .p-menuitem-link {
    color: red !important;
}

.b2g-v2.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
    background-color:var(--greylight) !important;
    /*color:var(--black) !important;*/
    border-color: var(--steel-20);
}

.b2g-v2.p-tabmenu-nav .p-menuitem-text {
    text-wrap: nowrap;
    font-size: 11pt;
}

.p-tabmenu .p-tabmenu-nav {
    background: var(--white);
    border: 1px solid var(--blue-10);
    border-width: 0 0 2px 0;
}

.p-tabmenu-nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-wrap: nowrap;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid var(--blue-10);
    border-width: 0 0 2px 0;
    border-color: transparent transparent var(--blue-10) transparent;
    background: var(--white);
    color: var(--gray-80);
    padding: 1.25rem;
    font-weight: 700;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
    height: calc(100% + 2px);
}

.p-tabmenu-nav a {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    text-decoration: none;
    overflow: hidden;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: var(--white);
    border-color: var(--blue-v1);
    color: var(--blue-v1);
}

/*.p-menuitem-link:focus {
    border: solid 3px var(--sky) !important;
    box-shadow: solid var(--sky) !important;
}*/

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: var(--white);
    border-color: var(--gray-80);
    color: var(--gray-80);
}

a.p-menuitem-link {
    text-decoration: none !important;
}

a.p-menuitem-link:hover {
    text-decoration: none !important;
}

a.p-menuitem-link:focus {
    border-radius: 0px;
    outline: 0px solid var(--sky);
    outline-offset: 0;
    border-color:none !important;
}

/* Dialog Popup */
.p-confirm-popup .p-confirm-popup-message {
    margin-left: 0;
}

/* Progress Bar */
.p-progressbar-determinate .p-progressbar-value-animate {
    border: 1px solid var(--green-80);
}

.p-progressbar-label {
    font-weight: bold;
}

/* Accordion */
.p-accordion .p-accordion-header {
    font-weight: 500;
}

.p-accordion-header .p-accordion-header-link {
    text-decoration: none;
    color: black;
}

/* Steps */
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    background: white;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: var(--blue-10);
    color: var(--blue);
    border: 1px solid var(--blue-40);
    font-weight: bold;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number.past {
    background: var(--black-20);
    border: 1px solid var(--black-60);
}

/*Google search was showing up below the modal itself, 2024-03-26*/
.p-dialog-mask {
	z-index: 1000 !important; 
}

/* Scroll Panel */
.p-scrollpanel.custombar1 .p-scrollpanel-bar {
    background-color: var(--primary-300);
    opacity: 1;
    transition: background-color .3s;
}

.p-scrollpanel .p-scrollpanel-bar {
    background: #0d6efd;
    border: 0 none;
    color: blue;
}

.p-scrollpanel .p-scrollpanel-bar-y{
    background: var(--steel-80);
    opacity: 1;
}

.p-scrollpanel-wrapper {
    border-right: 10px solid var(--blue-10);
}

/* Panel */
.p-panel .p-panel-header {
    background: var(--gray-10);
}

/* Chip */
.p-chip {
    background-color: #dee2e6;
    border-radius: 16px;
    color: #212529;
    padding: .25rem .75rem;
}

.p-chip .p-chip-text {
    line-height: 1.5;
    margin-bottom: .5rem;
    margin-top: .5rem;
}

/* Icons */
.pi-pencil {
    color: var(--blue-80);
}

.p-input-icon-left > i {
    left: 0.75rem;
    color: #495057;
 }

 .p-input-icon-left>.p-inputtext {
    padding-left: 2.25rem;
}

/* Fieldset */
.p-fieldset .p-fieldset-legend {
    background: var(--gray-10);
    padding: .75rem!important;
    border-radius: 4px;
    color: #212529;
}

.p-fieldset, .p-fieldset .p-fieldset-legend {
    border: 1px solid var(--black-30);
}

.p-fieldset-scroll .p-fieldset-content {
    max-height: 30rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0;
    padding-bottom: 0;
    overflow: auto;
}

/* Radio Button */
.p-radiobutton.p-highlight .p-radiobutton-box {
    border-color: var(--blue);
    background: var(--blue-v1);
}

 /* List */
 ol, ul {
    padding-left: 0rem;
    padding-bottom: 0rem;
}

/* File Upload */
.p-fileupload .p-fileupload-content {
    border: 1px solid var(--black-30);
}

.p-fileupload-buttonbar {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid var(--black-30);
    border-bottom: 0 none;
}

.p-fileupload-row {
    display: flex;
    align-items: center;
}

/* Password */
.p-icon-field>.p-input-icon {
    margin-top: -.75rem;
}

/* Card */
.card {
    border: 1px solid var(--black-30);
}












