/* Base level styling */
* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:root {
    /* V1 Color Variables */
    --blue-v1: #000099;
    --green-v1: #53993D;
    --purple-v1: #7440FF;
    --bluealternate: #0000FF;
    /*--bluelight: #1F4788;*/
    --bluelight: #1A1AA3;
    /*--green: rgb(0, 102, 0); #006600*/
    --green10: rgba(0, 102, 0, .1); /*#006600*/
    --green90: rgba(0, 102, 0, .9); /*#006600*/
    --yellow: rgb(255, 199, 0); /*#FFC700*/
    --yellow10: rgba(255, 199, 0, .1); /*#FFC700*/
    --yellowalternate: rgb(255, 190, 28);
    --grey: rgb(227,227,227); /*#E3E3E3*/
    --grey50: rgba(227,227,227, .5); /*#E3E3E3*/
    --greyalternate: rgb(201,201,201); /*#c9c9c9*/
    --greylight: rgba(227,227,227, 1);
    --greyverylight: #fafafa;
    --greydark: #616161;
    --redlight: rgba(255,204,204, 1);
    --red: red;
    --red10: rgb(221, 55, 55, .1);
    --maroon: maroon;
    /* V3 Color Variables */
    --black: #000000;
    --black-90: #1A1A1A;
    --black-80: #333333;
    --black-70: #4D4D4D;
    --black-60: #666666;
    --black-50: #808080;
    --black-40: #999999;
    --black-30: #B3B3B3;
    --black-20: #BFBFBF;
    --black-10: #E6E6E6;
    --gray: #5D676F;
    --gray-90: #6D767D;
    --gray-80: #7D858C;
    --gray-70: #8E959A;
    --gray-60: #9EA4A9;
    --gray-50: #AEB3B7;
    --gray-40: #BEC2C5;
    --gray-30: #CED1D4;
    --gray-20: #DFE1E2;
    --gray-15: #E7E8E9;
    --gray-10: #EFF0F1;
    --gray-5: #F7F7F8;
    --white: #FFFFFF;
    --alert-red-dark-20: #330000;
    --alert-red-dark-40: #660000;
    --alert-red-dark-60: #990000;
    --alert-red-dark-60: #CC0000;
    --alert-red-light: #FF0000;
    --alert-red-light-80: #FF3333;
    --alert-red-light-60: #FF6666;
    --alert-red-light-40: #FF9999;
    --alert-red-light-20: #FFCCCC;
    --marigold: #F6C64D;
    --marigold-90: #F7CC5F;
    --marigold-80: #F8D171;
    --marigold-70: #F9D782;
    --marigold-60: #FADD94;
    --marigold-50: #FAE2A6;
    --marigold-40: #FBE8B8;
    --marigold-30: #FCEECA;
    --marigold-20: #FDF4DB;
    --marigold-10: #FEF9ED;
    --blue: #012169;
    --blue-90: #1A3778;
    --blue-80: #344D87;
    --blue-70: #7079A3;
    --blue-60: #677AA5;
    --blue-50: #8090B4;
    --blue-40: #99A6C3;
    --blue-30: #B3BCD2;
    --blue-20: #CCD3E1;
    --blue-10: #E6E9F0;
    --steel: #32407B;
    --steel-90: #475388;
    --steel-80: #5B6695;
    --steel-70: #7079A3;
    --steel-60: #848CB0;
    --steel-50: #989FBD;
    --steel-40: #ADB3CA;
    --steel-30: #C2C6D7;
    --steel-20: #D6D9E5;
    --steel-10: #EBECF2;
    --light-blue: #EAEBF1;
    --green: #275E37;
    --green-90: #3D6E4B;
    --green-80: #527E5F;
    --green-70: #688E73;
    --green-60: #7D9E87;
    --green-50: #93AE9B;
    --green-40: #A9BFAF;
    --green-30: #BECFC3;
    --green-20: #D4DFD7;
    --green-10: #E9EFEB;
    --fern: #5DBB63;
    --fern-80: #7DC982;
    --fern-60: #9ED6A1;
    --fern-40: #BEE4C1;
    --fern-20: #DFF1E0;
    --pale-green: #D7EED8;
    --teal: #3E7D85;
    --teal-90: #518A91;
    --teal-80: #65979D;
    --teal-70: #78A4AA;
    --teal-60: #8BB1B6;
    --teal-50: #9EBEC2;
    --teal-40: #B2CBCE;
    --teal-30: #C5D8DA;
    --teal-20: #D8E5E7;
    --teal-10: #ECF2F3;
    --sky: #71B5DA;
    --sky-90: #7FBCDE;
    --sky-80: #8DC4E1;
    --sky-70: #9CCBE5;
    --sky-60: #AAD3E9;
    --sky-50: #B8DAEC;
    --sky-40: #C6E1F0;
    --sky-30: #D4E9F4;
    --sky-20: #E3F0F8;
    --sky-10: #F1F8FB;
    --royal: #6230AD;
    --royal-90: #7245B5;
    --royal-80: #8159BD;
    --royal-70: #916EC6;
    --royal-60: #A183CE;
    --royal-50: #B097D6;
    --royal-40: #C0ACDE;
    --royal-30: #D0C1E6;
    --royal-20: #E0D6EF;
    --royal-10: #EFEAF7;
}
/*
@font-face {
    font-family: 'Open Sans';
    src: url('../../fonts/OpenSans-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('../../fonts/OpenSans-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}*/
html{
    /*font-size:10px;*/
    overflow-x: hidden;
}

body {
    font-family: 'Open Sans';
    /*font-size: 1.25rem;*/
    font-variant-ligatures: none !important;
    font-feature-settings: "liga" 0;
    margin:0;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
}

a {
    color:var(--blue-v1);
    cursor: pointer;
}
a:visited {
    /*color: var(--green);*/
    color: var(--blue-v1);
}
a:hover{
    color:red;
}

input, textarea{
    color: var(--blue-v1) !important;
    font-family: 'Open Sans' !important;
    font-weight: bold !important;
}

select:focus, textarea:focus{
  outline: 3px solid var(--sky) !important;
  outline-offset: 0;
  border-color:var(--sky) !important;
}

a:focus{
    border-radius: 3px;
    outline: 3px solid var(--sky);
    outline-offset: 0;
    border-color:var(--sky) !important;
}

input:focus, button:focus{
    border-radius: 3px;
    outline: 3px solid var(--sky) !important;
    outline-offset: 0;
    border-color:var(--sky) !important;
}

/* Utilities Classes*/
.margin-b-5, .margin-b-5 {
    margin-bottom: 5px !important;
}
.margin-b-10, .margin-b-10 {
    margin-bottom: 10px !important;
}
.margin-t-5, .margin-t-5 {
    margin-top: 5px !important;
}

.margin-t-10, .margin-t-10 {
    margin-top: 10px !important;
}
.margin-0, .margin-0 {
    margin: 0 !important;
}

.padding-0, .padding-0 {
    padding: 0 !important;
}

.text-lg, .text_lg {
    font-size:1.1rem !important;
}
.text-xl, .text_xl {
    font-size: 180% !important;
}

.text-left, .text_left {
    text-align: left !important;
}

.text-right, .text_right {
    text-align: right !important;
}
.text-center, .text_center{
    text-align:center !important;
}
.text-red, .text_red{
    color:var(--red) !important;
}
.text-green, .text_green{
    color:var(--green) !important;
}
.pull-right{
    float:right;
}
.pull-left{
    float:left;
}
.width-100{
    width:100% !important;
}

.small-text, .text-sm, .text-sm {
    font-size: 80% !important;
}
.bold-text, .text-bold, strong, b {
    font-weight: bold;
}
.v-middle{
    vertical-align:middle;
}

.font-greydark{
    color:var(--greydark);
}


.section-box {
    margin: 1.5rem 0 1.5rem 0;
    padding: 10px;
    border: 1px solid gray;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}
.section-box.red {
    margin: 1.5rem 0 1.5rem 0;
    padding: 10px;
    border: 1px solid #B32B23;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}
.section-box.no-border {
    padding: 0;
    border: none;
    -webkit-border-radius: none;
    -moz-border-radius: none;
    -ms-border-radius: none;
    border-radius: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.section-box.left-align {
    width: 49%;
    float: left;
    display: inline-block;
}

.section-box.right-align {
    width: 49%;
    float: right;
    display: inline-block;
}


    .section-box .title {
        font-size: 16px;
        margin-top: 0px;
        margin-bottom: 10px;
        background-color: var(--grey);
        margin: -10px -10px 10px -10px;
        padding: 12px 10px 12px 10px;
        font-weight: bold;
    }
    .section-box .title-red {
        font-size: 16px;
        margin-top: 0px;
        margin-bottom: 10px;
        background-color: #B32B23;
        margin: -10px -10px 10px -10px;
        padding: 10px 10px 10px 10px;
        font-weight: bold;
        color:white;
    }
    .section-box .title-green {
        font-size: 16px;
        margin-top: 0px;
        margin-bottom: 10px;
        background-color: #275E37;
        margin: -10px -10px 10px -10px;
        padding: 10px 10px 10px 10px;
        font-weight: bold;
        color:white;
    }
    .section-box .title .title-button, .section-box .title .title-edit-button {
        text-align: right;
        margin-top: -24px;
    }
        .section-box .title .title-button .button,
        .section-box .title .title-edit-button .button {
            border: 1px solid var(--greydark);
            font-size: 1.2rem;
            padding: 3px 5px;
            font-weight:normal;
        }
    .section-box .section-box .title {
        background-color: var(--greydark);
        color:white;
    }
    .section-box .instructions {
        font-size: .8rem;
        padding-top:5px;
        padding-bottom:10px;
        font-weight: 400;
    }

    .instructions {
        font-size: .8rem;
    }

    .section-box .row {
        margin-bottom: 15px;
    }

    .section-box .field-label {
        text-transform: uppercase;
        font-size: .9rem !important;
    }

        .section-box .field-label {
            color: black;
            font-weight: normal;
        }

            .section-box .required-entry:after {
                content: ' *';
                color: red;
            }

            .required-entry:after {
                content: ' *';
                color: red;
            }

            .section-box .suggested-entry:after {
                content: ' *';
                color: blue;
            }

    .section-box .field-data {
        font-weight:bold;
        font-size:.9rem;
    }

    .section-box .field-list td {
        border-bottom: 1px solid #E0E0E0;
    }

    .section-box .tabulation th {
        background: #F0F0F0;
        font-size: 80%;
        font-weight:bold;
    }

    .section-box .tabulation td { 
    }

    .section-box .tabulation .data {
    }

    .section-box .tabulation .data-caps {
        text-transform: uppercase;
    }

    .section-box .tabulation .data-small {
        font-size: 80%;
    }

    .section-box .tabulation-grid th {
        border-bottom: 1px solid gray;
        background: #F0F0F0;
        font-size: 80%;
        font-weight:bold;
        text-align: center;
    }

    .section-box .tabulation tr.inactive, .section-box .tabulation-grid tr.inactive {
        background-color: #F0F0F0;
        color: #808080;
    }

    .section-box .tabulation tr.bg-red, .section-box .tabulation-grid tr.bg-red {
        background-color: var(--redlight);
    }

    .section-box .tabulation tr.bg-green, .section-box .tabulation-grid tr.bg-green {
        background-color: #D9FFCC;
    }

    .section-box .tabulation tr.bg-grey, .section-box .tabulation-grid tr.bg-grey {
        background-color: var(--grey);
    }

    .section-box .tabulation-grid td {
        border-bottom: 1px solid #E0E0E0;
    }

    .section-box .tabulation-grid .data {
    }

    .section-box .tabulation-grid .data-caps {
        text-transform: uppercase;
    }

    .section-box .tabulation-grid .data-small {
        font-size: 80%;
    }

    .section-box .tabulation-grid header {
        border-bottom: 1px solid gray;
        background: #F0F0F0;
        font-size: 80%;
        font-weight:bold;
        text-align: center;
    }
    /* Sorting / filtering / paging list styling */
    .section-box .tabulation-grid th .sort, .section-box .tabulation-grid th .sort {
        cursor: pointer;
        color: #000099;
        text-decoration: underline;
        margin-bottom: 5px;
    }

        .section-box .tabulation-grid th .sort:hover, .section-box .tabulation-grid th .sort:hover {
            color: red;
        }

        .section-box .tabulation-grid th .sort.asc, .section-box .tabulation-grid th .sort.desc {
            padding-right: 10px;
        }

    .section-box .tabluation-grid th .sort.asc:after {
        content: ' ';
        position: relative;
        left: 2px;
        border: 8px solid transparent;
        bottom: 15px;
        border-bottom-color: blue;
    }

    .section-box .tabluation-grid th .sort.desc:after {
        content: ' ';
        position: relative;
        left: 2px;
        border: 8px solid transparent;
        top: 10px;
        border-bottom-color: blue;
    }

    .section-box .tabulation-grid th .sort.asc:after {
        content: ' ';
        position: relative;
        left: 5px;
        border: 5px solid transparent;
        bottom: 10px;
        border-bottom-color: #006600;
    }

    .section-box .tabulation-grid th .sort.desc:after {
        content: ' ';
        position: relative;
        left: 5px;
        border: 5px solid transparent;
        top: 10px;
        border-top-color: #006600;
    }

    .section-box .tabulation-grid th .sort a:visited {
        color: #000099 !important;
    }
    .section-box .tabulation-grid table.hover tr:hover {
        background-color: var(--greylight);
    }

    .section-box .tabulation table.hover tr:hover {
        background-color: var(--greylight);
    }
.section-box-top-content{
    margin-top: 1.5rem;
    margin-bottom:0.25rem;
    font-size:0.8rem;
}
    .section-box-top-content .required-instruction{
        font-weight:bold;
        color: red;
    }

    table.table {
        width: 100%;
        border-collapse: collapse;
    }

        table.table tr th,
        table.table tr td {
            padding: 5px;
            margin: 0;
        }

            table.table tr th.col-10,
            table.table tr td.col-10 {
                width: 10%;
            }

            table.table tr th.col-20,
            table.table tr td.col-20 {
                width: 20%;
            }

            table.table tr th.col-30,
            table.table tr td.col-30 {
                width: 30%;
            }

            table.table tr th.col-40,
            table.table tr td.col-40 {
                width: 40%;
            }

            table.table tr th.col-50,
            table.table tr td.col-50 {
                width: 50%;
            }

            table.table tr th.col-60,
            table.table tr td.col-60 {
                width: 60%;
            }

            table.table tr th.col-70,
            table.table tr td.col-70 {
                width: 70%;
            }

            table.table tr th.col-80,
            table.table tr td.col-80 {
                width: 80%;
            }

            table.table tr th.col-90,
            table.table tr td.col-90 {
                width: 90%;
            }

        table.table td.actions a {
            font-weight:bold;
            text-decoration: underline;
            cursor: pointer;
        }

        table.table td.actions {
            text-align: center;
        }

        table.table tfoot td {
            font-weight: bold;
            border-top: 1px solid black !important;
            border-bottom: 1px solid black !important;
        }

input[type=file].dragdrop {
    /* display: none; */ padding: 20px 40px;
    border: 2px dashed #e3e3e3 !important;
}



/* Buttons */
.button-box {
    width: 100%;
    text-align: center;
    padding: 20px;
}

    .button-box .button:not(:last-child) {
        margin-right: 5px;
    }

    .button-box.left-align {
        text-align: left;
        padding-left: 0px;
    }

    .button-box.right-align {
        text-align: right;
        padding-right: 0px;
    }

    .button-box.no-padding{
        padding-top:0;
    }

.content-box {
    /*
    font-size: 12px;
    margin: 15px 0 15px 0;
    */
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

/* Buttons*/
a.button {
    text-decoration: none;
}

button.button, a.button {
    cursor: pointer;
    color: var(--black);
    font-family: 'Open Sans';
    padding: 6px 8px;
    font-size: 14px;
    border: 1px solid var(--greyalternate);
    text-decoration: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    background-clip: padding-box;
    background: var(--grey);
    /*background: #e7e7e7;
    background: -webkit-linear-gradient(-90deg, #e7e7e7, #c6c6c6);
    background: linear-gradient(180deg, #e7e7e7, #c6c6c6);*/
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

    button.button:hover, a.button:hover {
        border: 1px solid var(--greydark);
    }

.button.button-secondary {
    background: var(--bluelight);
    color: var(--white);
    border: 1px solid var(--blue);
}

    .button.button-secondary:hover, .button.button-secondary:focus, .button.button-secondary:active {
        background: var(--blue);
        border: 1px solid var(--black);
    }

.button.button-outline {
    background: transparent;
    color: var(--black);
    border: 1px solid var(--greydark);
}

    .button.button-outline:hover, .button.button-outline:focus, .button.button-outline:active {
        border: 1px solid var(--black);
    }

.button.button-save, .button.button-save {
    background: var(--yellowalternate);
    border: 1px solid var(--yellowalternate);
}

    .button.button-save:hover, .button.button-save:focus, .button.button-save:active {
        background: var(--yellow);
        border: 1px solid var(--greydark);
    }
.button.button-save-2 {
    background: var(--greenlight);
    color: var(--white);
    border: 1px solid var(--greenlight);
}

    .button.button-save-2:hover, .button.button-save-2:focus, .button.button-save-2:active {
        background: var(--green);
        border: 1px solid var(--black);
    }
.button.button-error {
    background: var(--reddark);
    color: var(--white);
    border: 1px solid var(--reddark);
}

    .button.button-error:hover, .button.button-error:focus, .button.button-error:active {
        background: var(--reddark);
        border: 1px solid var(--greydark);
    }

.button.button-xs {
    padding: 1px 2px;
    font-size: 1.1rem;
}

.button.button-sm {
    padding: 2px 4px;
    font-size: 1.3rem;
}

.button.button-lg {
    padding: 8px 10px;
    font-size: 1.6rem;
}

.button.button-xl {
    padding: 10px 12px;
    font-size: 16px;
}


/*Tiles*/
.tile {
    text-decoration: none;
    font-weight:bold;
    text-align: center;
    color: var(--blue);
    width: 100%;
    background: var(--greylight);
    border-radius: 3px;
    margin-bottom: 10px;
}
.tile.hover{
    cursor: pointer;
}
.tile.left{
    text-align:left;
}
    .tile .tile-title{
        height:40px;
    }
    .tile .tile-description{
        height:55px;
        overflow:auto;
    }
    .tile .tile-description.collapsed{
        height:14px;
        overflow:hidden;
    }
    .tile .tile-title {
        font-size: 1.3rem;
        padding: 10px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .tile .tile-description {
        font-size: .8rem;
        padding: 5px 10px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    /* Standard tile design */
    .tile.blue:not(.secondary) .tile-title {
        background-color: var(--blue);
        color: white;
    }

    .tile.blue:not(.secondary) .tile-description {
        background-color: var(--bluelight);
        color: white;
    }

    .tile.blue:not(.secondary) .b2g-collapse-indicator {
        color: white;
    }
    .tile.green:not(.secondary) .tile-title {
        background-color: var(--green);
        color: white;
    }

    .tile.green:not(.secondary) .tile-description {
        background-color: var(--greenlight);
        color: white;
    }
    .tile.yellow:not(.secondary) .tile-title {
        background-color: var(--yellowalternate);
        color: black;
    }
    .tile.yellow:not(.secondary) .tile-description {
        background-color: var(--yellowalternate);
        color: var(--greydark);
    }
    .tile.red:not(.secondary) .tile-title {
        background-color: var(--reddark);
        color: white;
    }
    .tile.red:not(.secondary) .tile-description {
        background-color: var(--reddark);
        color: black;
    }

    .tile.hover:hover:not(.secondary) .tile-title, .tile.active:not(.secondary) .tile-title {
        background-color: var(--blue);
        color: white;
    }
    .tile.hover:hover:not(.secondary) .tile-description, .tile.active:not(.secondary) .tile-description {
        background-color: var(--blue);
        color: white;
    }
    .tile.blue.hover:hover:not(.secondary) .tile-title, .tile.blue.active:not(.secondary) .tile-title {
        background-color: var(--bluelight);
        color: white;
    }

    .tile.blue.hover:hover:not(.secondary) .tile-description, .tile.blue.active:not(.secondary) .tile-description {
        background-color: var(--blue);
        color: white;
    }
    .tile.green.hover:hover:not(.secondary) .tile-title, .tile.green.active:not(.secondary) .tile-title {
        background-color: var(--greenlight);
        color: white;
    }

    .tile.green.hover:hover:not(.secondary) .tie-description, .tile.green.active:not(.secondary) .tile-description {
        background-color: var(--green);
        color: white;
    }

    /* Alternate tile design */
    .tile.secondary .tile-title {
        background-color: var(--grey);
    }

    .tile.secondary.blue .tile-title {
        background-color: var(--blue);
        color: white;
    }

    .tile.secondary.green .tile-title {
        background-color: var(--green);
        color: white;
    }
    .tile.secondary.hover:hover .tile-title, .tile.secondary.active .tile-title {
        background-color: var(--greydark);
        color: white;
    }

    .tile.secondary.blue.hover:hover .tile-title, .tile.secondary.blue.active .tile-title {
        background-color: var(--bluelight);
    }

    .tile.secondary.green.hover:hover .tile-title, .tile.secondary.green.active .tile-title {
        background-color: var(--greenlight);
    }

    /* Small Tile Design */
    .tile.small {
        min-width: 50px;
        max-width:80px;
        background: var(--greylight);
        border-radius: 3px;
        margin-bottom: 5px;
        display: inline-block;
        margin-right: 5px;
    }
        .tile.small .tile-title {
            font-size: 1.3rem;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }

    /* Badge Tile Design */
    .badge{
        display:inline-block;
        border-radius:3px;
        background-color:var(--greydark);
        padding:5px 5px;
        color:white;
        font-size:.9rem;
        font-weight:bold;
        margin-right:5px;
    }
    .badge.blue, .badge.info{
        background-color:var(--bluelight);
    }
    .badge.green, .badge.success{
        background-color:var(--green);
    }
    .badge.yellow, .badge.warning{
        background-color:var(--yellowalternate);
        color:black;
    }
    .badge.red, .badge.danger, .badge.error{
        background-color:var(--red);
    }


@media (min-width: 992px) {
}





/*Alerts*/

div.alert-container {
    padding: 0;
    margin: 0;
}

    /*Option 1*/
div.alert.active {
    position: fixed;
    top: 45px;
    right: 15px;
    align-items: center;
    vertical-align: middle;
    animation: slide-left 0.5s;
    transform: translateX(0);
    color: #eee;
    font-size: 1.3rem;
    padding: 14px 20px;
    border-radius: 3px;
    min-width: 300px;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    z-index: 9999;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}


/*Option 2 slides down from top*/
/*div.b2g-alert.active {
    position: absolute;
    top: 0;
    width: 100vw;
    vertical-align: middle;
    opacity: 1;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    animation: slide-down 1s ease forwards;
    margin-bottom: 10px;
    z-index: 10;
}*/

div.alert {
    display: none;
}


.alert.active.success {
    background: green;
}

.alert.active.error {
    background: var(--reddark);
}

.alert.active.warning {
    background: var(--yellow);
    color: black;
}

.alert.active.info {
    background: var(--blue);
}

.alert.active.default {
    background: #e0e0e0;
    color: black;
}

div .alert-wrapper span.fa {
    vertical-align: middle;
    margin-right: 5px;
}

div .alert-wrapper span.alert-message {
    vertical-align: middle;
    font-size: 1.3rem;
}

.alert span.fa {
    font-size: 1.4rem;
}

.alert span.fa-times-circle {
    cursor: pointer;
}

.warning-bar {
    padding: 10px;
    border-radius: 3px;
    color: var(--black);
    border: 1px solid var(--greydark);
    background-color:var(--greylight);
}
    .warning-bar.danger, .warning-bar.red {
        border: 1px solid red;
        background-color: var(--redlight);
    }



@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {
}



.b2g-input-container .ms-parent{
    width:100%;
}

::placeholder, ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #BBBBBB;
    opacity: 1; /* Firefox */
    font-family: 'Open Sans';
    font-weight:normal;
}

.required-ph::placeholder {
    color: rgb(253, 96, 96) !important;
  }

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #BBBBBB;
    font-family: 'Open Sans';
    font-weight: normal;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #BBBBBB;
    font-family: 'Open Sans';
    font-weight: normal;
}

/* Footer */
#footer{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
#footer a,
#footer span{
    font-size:.8rem;
}
.small-green-text
{
    COLOR: #006600;
    FONT-FAMILY: Verdana, sans-serif;
    FONT-SIZE: 10px
}

button.link-button {
    color: var(--blue-v1) !important;
    background: none !important;
    border: none !important;
    text-decoration: underline;
    padding: 0 !important;
    margin: 0 !important;
}

button.link-button:hover {
    color:red !important;
    border: none !important;
}

.button.link-button-v2 {
    color: var(--white) !important
}

.button.link-button-v2:hover {
    color: var(--black) !important
}

/* Header Info Bar */
.header-info-bar {
    background-color: var(--marigold-20);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

/* Dashboard */
.dashboard-content {
    margin-bottom: 0.75rem;
}

.dashboard-content .section-box {
    margin-bottom: 0.75rem;
}

.disclaimer {
    text-align:right; 
    clear:both; 
    font-size:10px; 
    color:#333;
}

/* Page Background */
.b2g-background-gray {
    background-color: var(--gray-20) !important;
}

/* Drawer */
.drawer-wrapper {
    margin: 0;
    padding: 0;
}

.drawer-container {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.drawer-sidebar {
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-width: 0;
    background: #E6E9F0;
    /* This property governs the drawer open/close speed */
    overflow-x: hidden;
    transition: 0.5s;
}

.drawer-resizer {
    flex-basis: 24px;
    position: relative;
    z-index: 2;
    cursor: col-resize;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.drawer-main {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    height: 100%;
    flex-direction: row;
    position: relative;
    margin: 0;
    padding: 0;
}

.drawer-button {
    border-radius: 0px 5px 5px 0px;
    height: 100%;
    width: 100%;
    border: none;
    cursor: pointer;
    background: #E6E9F0;
}

.drawer-button:hover {
    background: #cfd1d8
}

.drawer-inner {
    height: 100%;
    width: 100%;
    background: #E6E9F0;
    border-radius: 5px 0px 0px 5px;
    padding: 0.1rem;
}

.disable-external-styles * {
    all: revert;
}

.disable-external-styles pre {
    white-space: pre-wrap;
}

.stacked-progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    transition: var(--bs-progress-bar-transition);
    border-top: 1px;
    border-bottom: 1px;
    font-weight: bold;
    font-size: large;
}

.stacked-progress-bar-key {
    width: 1rem;
    height: 1rem;
    margin-top: 0.25rem;
    margin-left: 0.4rem;
    border-radius: 0.25rem;
}

.centered-button-box {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
}
