html, body, #root {
    height: 100%
}

#sidebar {
    padding-top: 1rem;
    padding-left: 5px;
    padding-right: 5px;
    display:inline-block;
    height:100%;
    width:20%;
    background-color:gold;
    position:fixed;
}

#content {
    display:inline-block;
    height:100%;
    width:80%;
    /*background-color:blue;*/
    margin-left: 20%;
}