/* 
    MODALS
*/

body.modal-active{
    overflow:hidden;
}

/* Page Modal */
#PageModal, #PageModaliFrame {
    border-radius: 2px;
}
#PageModalContainer, .page_modal_container {
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    position: absolute;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
#PageModal {
    display: block;
    width: 90%;
    height: 90%;
    background: #fff;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 50px;
    left: 45%;
    margin-left: -40%;
}
    #PageModal:not(.iframe) {
        overflow: auto;
    }

#PageModaliFrame {
    width: 100%;
    height: 100%;
}


    .modal_content {
        padding-left: 15px;
        padding-right: 15px;
    }

    .modal_header {
        margin-bottom: 8px;
        border-bottom: 1px solid #eee;
        z-index: 10;
        background-color: #fafafa;
        height: 60px;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
    }

        .modal_header .modal_title {
            position: relative;
            top: 13px;
            left: 10px;
            padding: 5px;
            float: left;
            color: var(--blue);
            font-weight:bold;
            font-size: 12pt;
        }

        .modal_header .modal_controls {
            float: right;
            position: absolute;
            top: 20px;
            right: 13px;
            cursor:pointer;
        }

            .modal_header .modal_controls .modal_control {
                height: 24px;
                width: 24px;
                display: inline-block;
                color: var(--greydark);
                font-size: 17px;
                text-align: center;
            }

                .modal_header .modal_controls .modal_control:hover {
                    color: black;
                }

                .modal_header .modal_controls .modal_control.close {
                    /*color:var(--reddark);*/
                    -webkit-transition: background-color 100ms linear;
                    -ms-transition: background-color 100ms linear;
                    transition: background-color 100ms linear;
                }

                .modal_header .modal_controls .modal_control a {
                    text-indent: -9999px;
                    position: absolute;
                    width: 24px;
                    height: 24px;
                }


@media (min-width: 576px) {
    /*
    .modal_container {
        padding: 5px 15px;
    }
    */
}

@media (min-width: 768px) {
    /*
    .modal_container {
        padding: 5px 30px;
    }
    */
}

@media (min-width: 992px) {
    /*
    .modal_container {
        padding: 5px 45px;
    }
    */
}

@media (min-width: 1200px) {
    /*
    .modal_container {
        padding: 5px 88px;
    }
    */
}
/*.modal_header .content .modal_controls .control.close a{
    background:url(../Images/window_controls/close_new.png) no-repeat center center transparent;
}*/
/*.modal_header .content .modal_controls .control.expand a{
    background:url(../Images/window_controls/fullscreen.png) no-repeat center center transparent;
}*/
/*.modal_header .content .modal_controls .control.shrink a{
    background:url(../Images/window_controls/shrink.png) no-repeat center center transparent;
}*/
.modal_header .content .modal_controls .control.download a {
    background: url(assets/v1/images/window_controls/download.png) no-repeat center center transparent;
}

.modal_controls .control.size .fa, .modal_controls .close .fa {
    font-size: 19px;
    cursor: pointer;
}

/* Content Modal */
.ContentModalContainer {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    left: 0%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.ContentModal {
    display: block;
    width: 80%;
    /*height: 30%;*/
    min-width: 400px;
    min-height: 200px;
    /*border: 1px solid black;*/
    background: #FFFFFF;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 20%;
    left: 30%;
    margin-left: -20%;
    padding: 20px;
    max-height: 60%;
    overflow-y: scroll;
    overflow-x: hidden;
}	

.ContentModal h1{
    font-weight:normal;
}

[data-dismiss=modal]{
    cursor:pointer !important;
}
